class ZIDXAccountSiteDesign implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSiteDesignContainer";
    }
    getMatchURL(){
        return "/account/settings/site-design";
    }
    render(){
        // console.log("site design");
    }
}